import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Weather = ({ type }) => {
  const data = useStaticQuery(graphql`
    query WeatherQuery {
      sunny: file(absolutePath: { regex: "/sunny.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cloudy: file(absolutePath: { regex: "/cloudy.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rainy: file(absolutePath: { regex: "/rainy.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      drizzle: file(absolutePath: { regex: "/drizzle.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const mapping = {
    sunny: data.sunny.childImageSharp.fixed,
    cloudy: data.cloudy.childImageSharp.fixed,
    rainy: data.rainy.childImageSharp.fixed,
    drizzle: data.drizzle.childImageSharp.fixed
  }

  return (
    <Image
      fixed={ mapping[type] }
      alt={type}
      style={{
        marginRight: rhythm(1 / 2),
        marginBottom: 0,
        minWidth: 50,
        borderRadius: `100%`,
      }}
      imgStyle={{
        borderRadius: `50%`,
      }}
    />
  )
}

export default Weather
